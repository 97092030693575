.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input[type="text"],
input[type="email"],
input[type="number"] {
font-size: 16px; /* Prevent zoom on mobile browsers */
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #315b99;
}

.email-link {
  color: #4fc3f7;
}

.welcome-message-container {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
  max-height: 60vh;
  overflow: auto;
  padding: 20px; /* Increased padding for better readability */
  border-radius: 10px;
}

.typewriter-text {
  font-family: Arial, sans-serif;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}
  
  .typewriter-text-1 {
    animation: typing1 1s steps(40) 1s 1 normal both, step-end 1s 1; /* Caret blinks for 5 seconds */
  }
  
  .typewriter-text-2 {
    animation: typing2 1s steps(60) 2s 1 normal both, step-end 3s 1; /* Starts after the first paragraph finishes */
  }
  
  .typewriter-text-3 {
    animation: typing3 1s steps(60) 4s 1 normal both, 1s step-end 6s 1; /* Starts after the second paragraph finishes */
  }

  .typewriter-text-4 {
    animation: typing4 1s steps(60) 7s 1 normal both, 1s step-end 8s 1; /* Starts after the second paragraph finishes */
  }
  
  .typewriter-text-5 {
    animation: typing5 1s steps(60) 8s 1 normal both, 1s step-end 11s 1; /* Starts after the second paragraph finishes */
  }

  .question-container {
    position: fixed; /* or 'absolute' depending on layout */
    top: 38%; /* Adjust as needed */
    left: 58%;
    transform: translate(-50%, -50%); /* Center the container */
    padding: 40px;
    text-align: center;
    width: 80%; /* Adjust width as needed */
    z-index: 10; /* Ensure it's below the article card */
    overflow: hidden;
  }
  
  .question-text {
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
    transform: translate(-10%, -50%);
  }
  
  .answer-button {
    background-color: white;
    color: #C91D1D; /* Adjust color as needed */
    padding: 15px 20px; /* Increased padding for thicker buttons */
    margin: 5px 0;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%; /* Percentage width for responsiveness */
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  }
  
  .answer-button img {
    height: 30px; /* Adjust size as needed */
    width: 30px; /* Adjust size as needed */
    border-radius: 50%;
    margin-right: 10px; /* Space between image and text */
  }

  @keyframes typing1 {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret1 {
    0%, 100% { border-right: 2px solid transparent; }
    50% { border-right: 2px solid white; }
  }
  
  @keyframes typing2 {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret2 {
    0%, 100% { border-right: 2px solid transparent; }
    50% { border-right: 2px solid white; }
  }
  
  @keyframes typing3 {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret3 {
    0%, 100% { border-right: 2px solid transparent; }
    50% { border-right: 2px solid white; }
  }

  @keyframes typing4 {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret4 {
    0%, 100% { border-right: 2px solid transparent; }
    50% { border-right: 2px solid white; }
  }

  @keyframes typing5 {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret5 {
    0%, 100% { border-right: 2px solid transparent; }
    50% { border-right: 2px solid white; }
  }


  @media screen and (max-width: 600px) {
    .welcome-message-container {
      max-width: 90%;
      top: 10%; /* Closer to the top */
    }
  }

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}

.avatar-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
  border: 2px solid white; 
}

.logoImage {
  margin-left: 10px;
  height:32px
}

.continue-button-container {
  position: fixed;
  bottom: 20%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer; /* To indicate clickability */
}

.continue-text {
  color: white; /* White text color */
  font-style: italic; /* Italic text */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 10px;
  margin-top: 20px; /* Adjust as needed */
  text-align: center; /* Center align the text */
}

.article-styles {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: white;
  z-index: 100;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3); /* Shadow on the top */
  touch-action: none; /* Important for drag functionality */
  z-index: 1000; /* Ensure it's above other content */
}

.article-content {
  padding: 20px;
  padding-bottom: 100px; /* Extra padding at the bottom */
  overflow-y: auto; 
  height: calc(100vh - 50px); /* Adjust based on the toolbar height */
  max-height: 90vh; 
  background-color: white;
}

.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh; /* Takes up 15% of the viewport height */
  background-color: white; /* White background */
  z-index: 900; /* Behind the article head in terms of z-index */
}

.tap-to-read {
  text-align: center;
  font-style: italic;
  padding-bottom: 20px; /* Padding between this area and the article headline */
}

.article-content {
  padding-bottom: 100px; /* Increase padding at the bottom of the article */
  /* ... other styles ... */
}

.article-arrow {
  display: inline-block;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.article-arrow-down {
  transform: rotate(180deg);
}

.selected-answer {
  background-color: green;
  color: white;
}

.next-question-button {
  background-color: white;
  color: #C91D1D; 
  padding: 10px 20px;
  margin-top: 250px; /* Increased margin for lower positioning */
  border: 2px solid #C91D1D; 
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-style: italic;
  transition: background-color 0.3s ease;
  z-index: 800; /* High z-index for prominence */
  position: relative; /* Required for z-index to take effect */
}

/* When the button is visible */
.next-question-button-show {
  opacity: 1;
}

.answer-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space between answer options */
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between the button and user info */
}

.avatar-circle {
  width: 15px; /* Size of the avatar */
  height: 15px; /* Size of the avatar */
  border-radius: 50%; /* Make it round */
  margin-right: 5px; /* Space between the avatar and user name */
}

.user-name {
  color: white; /* Color of the user name */
}

.referrer-answer-different {
  background-color: #6b5c5c; /* Grey background for the button */
  color: black; /* Change text color to black */
  font-style: italic; /* Italicize the text */
  /* Other styles as needed */
}

.extended-answer-text {
  font-size: 9.5pt; /* Reduced font size */
  text-align: left;
  margin-bottom: 0px; 
}

.avatar-image-outside {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 5px; /* Adjust this value to move the image left or right */
}

.slider-value {
  color: white;
  font-size: 30px; /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  font-style: italic;
  margin-bottom: 0px; /* Spacing between text and slider */
  margin-right: 50px;
}

.slider-input {
  width: 82%; /* Adjust width as needed */
  margin-right: 200px; /* Center the slider */
  left: 100px; 
}

.slider-submit-button {
  background-color: #C91D1D; /* Adjust background color as needed */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for size */
  margin-top: 10px; /* Margin for spacing */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to pointer on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  display: block; /* Make it a block element */
  width: 80%; /* Adjust width as needed */
  margin: 10px auto; /* Center the button */
  margin-right: 50px;
  position: fixed;
  left: 36%;
  right:20px;
}

.question-input {
  width: 85%; /* Adjust as per layout */
  padding: 10px;
  margin-bottom: 40px; /* Space between input and button */
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px; /* Readable text size */
  position: relative; /* Added for relative positioning */
  left: 36%; /* Center the element */
  transform: translateX(-50%); /* Adjust for exact centering */
}

/* Layout - If needed */
.question-form-container {
  text-align: center; /* Center aligning the elements */
  margin-top: 40px; /* Space from the top or previous element */
  margin-bottom: 40px;
}

.share-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px; /* Adjusted width */
}


.share-box h3 {
  margin-bottom: 15px;
}

.share-box input {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.share-box a {
  color: #C91D1D; /* Red color for Contact link */
  text-decoration: none;
  font-weight: bold;
}

.referrer-slider-info {
  position: absolute; /* Change to absolute positioning */
  text-align: center;
  color: white; /* Set text color to white */

  /* Positioning */
  top: 55%; /* Adjust this to move up/down; 80% is an example */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */
}

/* Rest of your existing styles */

.correct-answer-info, .referrer-info {
  color: white; /* Set text color to white */
  text-align: center;
  font-style: italic; /* Italic text */
  padding: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


